import React from 'react';
import { Link } from "react-router-dom";
import { EmailContainer, StyleLogo } from "./style";
import { useSelector } from "react-redux";
import { encryptVal } from "../../../utils/utility";
import AdminLogo from "../../../assets/images/logo.png";

const EmailContentSection = () => {

  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  return (
    <EmailContainer>
      <StyleLogo>
        <img
          src={AdminLogo}
          alt="AdminLogo"
          styel={{ padding: " -37px", width: "130px" }}
        />
      </StyleLogo>
      <hr />
      <p>
        <b>Dear Customer: {(typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.given_name) ? `${OrderDetails[0]?.given_name} ${OrderDetails[0]?.surname}` : ""}</b>
      </p>
      <p>We would like to inform you that your eTA application has been successfully
        approved.</p>
      <p>
        An eTA is automatically linked to your passport.
      </p>
      <p>
        To download your eTA confirmation.
      </p>
      <p>
        Please click on the link below
      </p>
      <p>Username :  <Link to="inquiries@korea-portal.online">{typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.email ? OrderDetails[0]?.email : ""} </Link></p>
      <p className="flex-class">
        <b>Order ID: </b>{typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.order_id ? OrderDetails[0]?.order_id : ""}
      </p>

      <Link to={`https://admin-sk.online/track-order/${typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.order_id ? encryptVal(OrderDetails[0]?.order_id) : ""}`}  target="_black" className="apply-btn">
        Click Here
      </Link>
      <br/>
      <br/>
      <p><b>eTA Validity:</b></p>
      <p>The eTA is valid until the Expiration date noted in the document.</p>
      <p>It is recommended, but not required, that you take a printed or digital copy of your
        eTA with you .</p>
      <p>Please make sure you check the official requirements and health regulations before
        traveling to your destination as travel restrictions may be applied in some cases.</p>
      <p>Contact us with any questions you may have regarding your eTA</p>

      <p>
      Should you have any inquiries, please contact us through our 
        <Link to="https://korea-portal.online/contact_us.php"> contact form </Link>or via email at {" "}
        <Link to="inquiries@korea-portal.online">inquiries@korea-portal.online  </Link>
        indicating your order ID.
      </p>

      <p className="flex-class">
        <b>Customer Service Number:</b> +1 (407) 759 7430
      </p>
      <p className="flex-class">
        <b>Order ID:</b>{typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.order_id ? OrderDetails[0]?.order_id : ""}
      </p>
      <p>
        <b>Important Notice:</b>
      </p>
      <p>
        {" "}
        If you are not satisfied with the service you have received or wish to file a
complaint, please contact us at <Link to="mailto:inquiries@korea-portal.online">inquiries@korea-portal.online </Link> ,
        where we will be happy to assist you or alternatively click on one of the links below for more information.
      </p>
      <p>Thank you for using our services.</p>
      <p> Regards,</p>
      <p><b>Processing Department</b></p>
      <p>
        <Link to="https://korea-portal.online/terms_condition.php">Terms</Link> |{" "}
        <Link to="https://korea-portal.online/privacy_policy.php">Privacy</Link> |{" "}
        <Link to="https://korea-portal.online/contact_us.php">Contact</Link> |{" "}
        <Link to="https://korea-portal.online/refund_policy.php">Refund</Link>
      </p>
    </EmailContainer>
  );
}

export default EmailContentSection