import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../features/order/orderSlice";
import { toast } from "react-toastify";

const ContactCustomer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const contactCustomersList = useSelector((state) => state?.order?.ordersList);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        dispatch(
        getOrdersList({
            orderName: "contactcustomerorder",
            page: page,
            perPage: limit,
        })).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, {
                className: "toast-message",
            });
            localStorage.removeItem("user");
            navigate("/");
            }
        });
        document.title = "Contact Customer | KeTA Visa Portal";
    }, [dispatch, navigate, page, limit]);

    const tableData = contactCustomersList;
    const handleClick = () => {
        window.location.reload();
      };
    return (
        <StyledContainer>
            <StyledPageTitle>
                <h1>Contact Customer</h1>
                <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>

            </StyledPageTitle>
            <form>
                <StyledOrderBlock>
                    <div className="table-block table-responsive">
                        <ReactDataTable
                        data={tableData}
                        setPage={setPage}
                        setLimit={setLimit}
                        orderName="contactcustomerorder"
                        />
                    </div>
                </StyledOrderBlock>
            </form>
        </StyledContainer>
    );
};

export default ContactCustomer;
