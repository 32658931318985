import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../pages/AdminLogin/Login";
import NewOrders from "../../pages/AdminDashboard/NewOrders";
import PendingOrders from "../../pages/AdminDashboard/PendingOrders";
import ContactCustomer from "../../pages/AdminDashboard/ContactCustomer";
import CompletedOrders from "../../pages/AdminDashboard/CompletedOrders";
import AwaitingResponse from "../../pages/AdminDashboard/AwaitingResponse";
import DeletedOrders from "../../pages/AdminDashboard/DeletedOrders";
import OrderHistory from "../../pages/AdminDashboard/OrderHistory";
import CustomerCalled from "../../pages/AdminDashboard/CustomerCalled";
import AwaitingGovt from "../../pages/AdminDashboard/AwaitingGovt";
import ManageTeam from "../../pages/AdminDashboard/ManageTeam";
import GatewaySetting from "../../pages/AdminDashboard/GatewaySetting";
import ChangePassword from "../../pages/AdminDashboard/ChangePassword";
import RefundOrders from "../../pages/AdminDashboard/RefundOrders";
import PageNotFound from "../../pages/PageNotFound";
import ViewOrdersDetails from "../../components/ViewOrderDetails/ViewOrderDetails";
import PriorityOrders from "../../pages/AdminDashboard/PriorityOrders/PriorityOrders";

const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route path="/" element={<NewOrders />} />
        <Route path="/recent-order" element={<NewOrders />} />
        <Route path="/priority-order" element={<PriorityOrders />} />
        <Route path="/pending-order" element={<PendingOrders />} />
        <Route path="/contact-customer" element={<ContactCustomer />} />
        <Route path="/completed-order" element={<CompletedOrders />} />
        <Route path="/awaiting-response" element={<AwaitingResponse />} />
        <Route path="/deleted-order" element={<DeletedOrders />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/called" element={<CustomerCalled />} />
        <Route path="/awaiting-govt" element={<AwaitingGovt />} />
        <Route path="/manage-team" element={<ManageTeam />} />
        <Route path="/manage-team/:teamMemberId" element={<ManageTeam />} />
        <Route path="/gateway-setting" element={<GatewaySetting />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/refund-order" element={<RefundOrders />} />
        <Route path="/view-order/:orderId" element={<ViewOrdersDetails />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
};

export default Main;
