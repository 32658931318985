import React, { useEffect } from "react";
import { StyledHeader } from "./style";

const Header = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <StyledHeader>
      <div id="topbar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2"></div>

                <div className="col-md-6"></div>

                <div
                  className="col-md-4 google_translation_section"
                  style={{
                    textAlign: "left",
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: "0px 0",
                  }}
                >
                  <div
                    id="google_translate_element"
                    style={{
                      marginTop: "2px !important",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  ></div>
                  <i className="bi bi-envelope emain_icon"></i>{" "}
                  <a
                    href="mailto: inquiries@korea-portal.online"
                    style={{ color: "#fff" }}
                  >
                    <span
                      className="__cf_email__"
                      style={{ marginLeft: "20px" }}
                    >
                    inquiries@korea-portal.online 
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
