import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StyledOrderBlock, StyledBottomBlock } from "./style";
import { FaDownload } from "react-icons/fa";
import rightArrow from "../../assets/images/right-arrow.png";
import {
  getOrderDetailsByOrderId,
  getUploadDocDetails,
  resendMail,
  uploadVisaDocument
} from "../../features/order/orderSlice";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
// import AddComment from "./AddComment/AddComment";
import AddCommentSection from "./AddCommentSection/AddCommentSection";
import MyModal from "../Modal/Modal";
import EmailContentSection from "./EmailContentSection";
// import DefenceContent from "../../pages/DefenceContent/DefenceContent";
// import TransactionModal from "../../pages/TransactionModal/TransactionModal";
import TransactionModalSection from "./TransactionModalSection/TransactionModalSection"
import DefencePackSection from "./DefencePackSection/DefencePackSection"


const ViewOrdersDetails = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [show, setShow] = useState(false);
  const [fullName, setFullName] = useState("");
  const [showDefence, setShowDefence] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [showDefencePack, setShowDefencePack] = useState(false);
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const UploadDocDetails = useSelector((state) => state?.order?.uploadDocDetails);
  const isLoading = useSelector((state) => state?.order?.loading);

  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });


  let uploadArr = {};
  if (UploadDocDetails?.length > 0 && UploadDocDetails !== null && UploadDocDetails != "undefined") {
    for (const item of UploadDocDetails) {
      uploadArr[item?.customer_name] = item?.file1;
    }
  }

  
  
  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId));
      // for displaying last record on the download button for add member in upload doc section
      dispatch(getUploadDocDetails(orderId));
    }
    
    document.title = "Orders Details | Korea eTA";
  }, [orderId]);

  const [emailId, setEmailId] = useState(typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.email ? OrderDetails[0]?.email : null);
  const [orderid, setEmailIdOrderid] = useState(typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.order_id ? OrderDetails[0]?.order_id : null);


  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
    fileUpload: yup.mixed().required("File is required"),
  });


  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.email &&
        typeof emailId === "undefined" && OrderDetails?.length > 0) ||
      orderId !== orderid
    ) {
      // setEmailId(OrderDetails[0]?.email);
      setEmailId(typeof OrderDetails !== "undefined" && OrderDetails?.length > 0 ? OrderDetails[0]?.email : "");
    }
  }, [OrderDetails, orderid]);

  let defaultInitialValues = {
    orderId: typeof OrderDetails !== "undefined" && OrderDetails?.length > 0 ? OrderDetails[0]?.order_id : "",
    email: "",
    fileUpload: "",
  };

  if (orderId !== null && orderId !== undefined && OrderDetails !== null && typeof OrderDetails !== "undefined" && OrderDetails?.length > 0) {
    defaultInitialValues = {
      orderId: OrderDetails[0]?.order_id,
      email: OrderDetails[0]?.email,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {

      const data = new FormData();
      data.append("orderId", values.orderId);
      data.append("email", values.email);
      data.append("uploadVisaDoc", values.fileUpload);
      data.append("fullName", fullName);
      dispatch(uploadVisaDocument(data))
        .unwrap()
        .then((res) => {
          resetForm();
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(getOrderDetailsByOrderId(orderId));
            dispatch(getUploadDocDetails(orderId));
          }
        });
    },
  });

  const handleResendMail = (e) => {
    e.preventDefault();
    const data = {
      orderId: typeof OrderDetails !== "undefined" && OrderDetails?.length > 0 ? OrderDetails[0]?.order_id : "",
      email: emailId,
    };

    dispatch(resendMail(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getOrderDetailsByOrderId(orderId));
          dispatch(getUploadDocDetails(orderId));
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {typeof OrderDetails !== "undefined" && OrderDetails?.length > 0 && OrderDetails[0]?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>#</th>
                      <th style={{ width: "15%" }}>Order ID</th>
                      <th style={{ width: "35%" }}>Download</th>
                      <th style={{ width: "10%" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails[0]?.mailHistory?.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td className="font-normal">{data?.order_id}</td>
                          {/* {OrderDetails?.map((item, index) => {
                            return ( */}
                          <tr>
                            <td key={1}>
                              <a
                                href={`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${data?.uploadDoc?.file1}`}
                                className="blue-btn"
                                // download
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download File -
                                <span>
                                  {`${data?.uploadDoc?.customer_name}`}
                                </span>
                              </a>
                            </td>
                          </tr>
                          {/* ); */}
                          {/* })} */}

                          <td>
                            {moment(data?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control input-class"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="blue-btn"
                      id="download_btn"
                      disabled={isLoading}
                      onClick={handleResendMail}
                    >
                      Resend Mail{" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className="table-block table-responsive fw-mobileview">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>Order ID</td>
                      <td style={{ width: "35%" }} className="font-normal">
                        <input
                          type="text"
                          name="orderId"
                          className="form-control"
                          placeholder="Order ID"
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: "15%" }}>Email ID</td>
                      <td style={{ width: "35%" }}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Email"
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>

                    {typeof OrderDetails != "undefined" && OrderDetails?.length !== 0 ? (
                      OrderDetails?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              PDF - {data?.given_name + " " + data?.surname}
                            </td>
                            <tr>
                              <td>
                                <label className="file-upload">
                                  <input
                                    type="file"
                                    className=""
                                    id="fileUpload"
                                    name="fileUpload"
                                    accept=".pdf,image/*"
                                    // disabled={values.fileUpload !== ""}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "fileUpload",
                                        e.currentTarget.files[0]
                                      )
                                      setFullName(data?.given_name + " " + data?.surname)
                                    }
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <span>Choose File</span>
                                </label>
                                <span className="slcted-file"></span>
                                <p>{errors.fileUpload}</p>
                              </td>
                            </tr>

                            <td colSpan="2">
                              {(OrderDetails?.uploadDoc?.length !== 0 && uploadArr.length !== 0 && uploadArr[data?.given_name + " " + data?.surname]) && (
                                <a
                                  href={`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${uploadArr[data?.given_name + " " + data?.surname] ? uploadArr[data?.given_name + " " + data?.surname] : ""}`}
                                  className="blue-btn"
                                  // download
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Download File -
                                  <span>
                                    {data?.given_name + " " + data?.surname}
                                  </span>
                                </a>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>PDF</td>
                        <tr>
                          <td>
                            <label className="file-upload">
                              <input
                                type="file"
                                className=""
                                id="fileUpload"
                                name="fileUpload"
                                accept=".pdf,image/*"
                                onChange={(e) =>
                                  setFieldValue(
                                    "fileUpload",
                                    e.currentTarget.files[0]
                                  )
                                }
                                onBlur={handleBlur}
                              />
                              <span>Choose File</span>
                            </label>
                            <span className="slcted-file"></span>
                            <p>{errors.fileUpload}</p>
                          </td>
                        </tr>
                      </tr>
                    )}
                  </tbody>
                </table>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn"
                  disabled={isLoading}
                >
                  Submit{" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>
        <div className="col-md-12">
          {typeof OrderDetails != "undefined" && OrderDetails?.length !== 0 &&
            OrderDetails?.map((item, index) => {

              return (
                <>
                  <StyledOrderBlock key={index}>
                    {/* <h2>Group Member {index + 1}</h2> */}
                    <h2>Order Details</h2>
                    <div className="table-block table-responsive">
                      <table>
                        <tbody>
                          {item?.order_id && (
                            <tr>
                              <td>Order ID:</td>
                              <td>{item?.order_id}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </StyledOrderBlock>

                  <StyledOrderBlock>
                    <h2>Enter E-Mail</h2>
                    <div className="table-block table-responsive">
                      <table>
                        <tbody>
                          {item?.continent && (
                            <tr>
                              <td>Continent</td>
                              <td>{item?.continent}</td>
                            </tr>
                          )}

                          {item?.continent_nationality && (
                            <tr>
                              <td>Your Nationality</td>
                              <td>{item?.continent_nationality}</td>
                            </tr>
                          )}

                          {item?.psno && (
                            <tr>
                              <td>Passport Number</td>
                              <td>{item?.psno}</td>
                            </tr>
                          )}

                          {item?.email && (
                            <tr>
                              <td>E-mail Address</td>
                              <td>{item?.email}</td>
                            </tr>
                          )}

                          {item?.confirm_email && (
                            <tr>
                              <td>Confirm E-mail Address</td>
                              <td>{item?.confirm_email}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </StyledOrderBlock>

                  <StyledOrderBlock>
                    <h2>Passport Information</h2>
                    <div className="table-block table-responsive">
                      <table>
                        <tbody>
                          {item?.passUpload && (
                            <tr>
                              <td>Upload passport bio page image file</td>
                              <td>
                                <Link
                                  to={process.env.REACT_APP_FRONT_APP_URL + item?.passUpload}
                                  target="_blank"
                                >
                                  Click here
                                </Link>
                              </td>
                            </tr>
                          )}

                          {item?.nationality && (
                            <tr>
                              <td>Nationality as written on your passport bio page</td>
                              <td>{item?.nationality}</td>
                            </tr>
                          )}

                          {item?.gender && (
                            <tr>
                              <td>Sex as written on your passport bio page</td>
                              <td>{item?.gender}</td>
                            </tr>
                          )}

                          {item?.given_name && (
                            <tr>
                              <td>Given name(including middle name) in English as written on your passport bio page</td>
                              <td>{item?.given_name}</td>
                            </tr>
                          )}

                          {item?.surname && (
                            <tr>
                              <td>Surname in English as written on your passport bio page</td>
                              <td>{item?.surname}</td>
                            </tr>
                          )}


                          {item?.dob && (
                            <tr>
                              <td>Date of birth as written on your passport</td>

                              <td>{item?.dob}</td>
                            </tr>
                          )}

                          {item?.passport_number && (
                            <tr>
                              <td>Passport number</td>

                              <td>{item?.passport_number}</td>
                            </tr>
                          )}

                          {item?.passport_exp_date && (
                            <tr>
                              <td>Expiration date of your passport</td>

                              <td>{item?.passport_exp_date}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </StyledOrderBlock>

                  <StyledOrderBlock>
                    <h2>Required Information</h2>
                    <div className="table-block table-responsive">
                      <table>
                        <tbody>
                          {item?.currently_in_koria && (
                            <tr>
                              <td>If you are also a national or citizen of another country other than the nationality of your passport</td>
                              <td>{item?.currently_in_koria}</td>
                            </tr>
                          )}

                          {item?.country && (
                            <tr>
                              <td>Country</td>
                              <td>{item?.country}</td>
                            </tr>
                          )}

                          {item?.countryCode && item?.mobile_number && (
                            <tr>
                              <td>Your mobile phone number</td>
                              <td>{`+${item.countryCode}-${item.mobile_number}`}</td>
                            </tr>
                          )}

                          {item?.visited_korea && (
                            <tr>
                              <td>Have you ever visited Korea before?</td>
                              <td>{item?.visited_korea}</td>
                            </tr>
                          )}

                          {item?.purpose && (
                            <tr>
                              <td>Your purpose of entry</td>
                              <td>{item?.purpose}</td>
                            </tr>
                          )}

                          {item?.other_tour_type && (
                            <tr>
                              <td>Other purpose of entry</td>

                              <td>{item?.other_tour_type}</td>
                            </tr>
                          )}
                          {item?.tour_type && (
                            <tr>
                              <td>Tour Type</td>

                              <td>{item?.tour_type}</td>
                            </tr>
                          )}
                          {item?.tour_type_2_value && (
                            <tr>
                              <td>Travel Agency Name</td>

                              <td>{item?.tour_type_2_value}</td>
                            </tr>
                          )}
                          {item?.agencyCountryCode && item?.agency_phone_number && (
                            <tr>
                              <td>Travel Agency Phone Number</td>

                              <td>{ `+${item?.agencyCountryCode}-${item?.agency_phone_number}`}</td>
                            </tr>
                          )}
                          {item?.stay_place_in_korea && (
                            <tr>
                              <td>The address and contact information of where you will stay during your visit in Korea</td>

                              <td>{item?.stay_place_in_korea}</td>
                            </tr>
                          )}

                          {item?.korea_pin_code && (
                            <tr>
                              <td>Post Code information of where you will stay during your visit in Korea</td>

                              <td>{item?.korea_pin_code}</td>
                            </tr>
                          )}

                          {item?.koria_contact_number && (
                            <tr>
                              <td>Your contact number in Korea</td>

                              <td>{item?.koria_contact_number}</td>
                            </tr>
                          )}

                          {item?.job_type && (
                            <tr>
                              <td>The category that best represents your current job</td>

                              <td>{item?.job_type}</td>
                            </tr>
                          )}

                          {item?.other_job_type && (
                            <tr>
                              <td>Other job type</td>

                              <td>{item?.other_job_type}</td>
                            </tr>
                          )}

                          {item?.diseases && (
                            <tr>
                              <td>Are you currently suffering from any of the following diseases?</td>

                              <td>{item?.diseases}</td>
                            </tr>
                          )}

                          {item?.diseases_comment && (
                            <tr>
                              <td>If you currently suffering from any of the following diseases.</td>

                              <td>{item?.diseases_comment}</td>
                            </tr>
                          )}

                          {item?.arrested && (
                            <tr>
                              <td>Have you ever been arrested, charged, or convicted of a criminal offense in your country or abroad?</td>

                              <td>{item?.arrested}</td>
                            </tr>
                          )}

                          {item?.arrested_comment && (
                            <tr>
                              <td>If you ever been arrested, charged, or convicted of a criminal offense in your country or abroad</td>

                              <td>{item?.arrested_comment}</td>
                            </tr>
                          )}


                        </tbody>
                      </table>
                    </div>
                  </StyledOrderBlock>

                  <StyledOrderBlock>
                    <h2>Portrait photo of applicant</h2>
                    <div className="table-block table-responsive">
                      <table>
                        <tbody>
                          {item?.profile_pic && (
                            <tr>
                              <td>Portrait photo of applicant</td>

                              <td>
                                <Link
                                  to={process.env.REACT_APP_FRONT_APP_URL + item?.profile_pic}
                                  target="_blank"
                                >
                                  Click here
                                </Link>

                                {/* {`korea-portal.online/${item?.profile_pic}`}</td> */}
                                {/* <td>{`korea-portal.online/${item?.profile_pic}`}</td> */}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </StyledOrderBlock>

                  <StyledOrderBlock>
                    <h2>Personal Information</h2>
                    <div className="table-block table-responsive">
                      <table>
                        <tbody>
                          {item?.birth_country && (
                            <tr>
                              <td>Select country of birth</td>
                              <td>{item?.birth_country}</td>
                            </tr>
                          )}

                          {item?.birth_place && (
                            <tr>
                              <td>Enter place of birth as written on your passport bio page</td>
                              <td>{item?.birth_place}</td>
                            </tr>
                          )}

                          {item?.korea_planned_duration1 && (
                            <tr>
                              <td>Enter planned duration of your stay in Korea from</td>
                              <td>{item?.korea_planned_duration1}</td>
                            </tr>
                          )}

                          {item?.korea_planned_duration2 && (
                            <tr>
                              <td>Enter planned duration of your stay in Korea to</td>
                              <td>{item?.korea_planned_duration2}</td>
                            </tr>
                          )}

                          {item?.acquaintances_korea && (
                            <tr>
                              <td>Do you have any acquaintances in Korea? If yes, please enter the information below</td>
                              <td>{item?.acquaintances_korea}</td>
                            </tr>
                          )}

                          {(item?.acquaintances_korea_name && item?.acquaintances_korea == "Yes") && (
                            <tr>
                              <td>Enter Name</td>
                              <td>{item?.acquaintances_korea_name}</td>
                            </tr>
                          )}

                          {item?.acquaintances_korea_contact && (
                            <tr>
                              <td>Enter contact information</td>
                              <td>{item?.acquaintances_korea_contact}</td>
                            </tr>
                          )}

                          {item?.acquaintances_korea_relation && (
                            <tr>
                              <td>Relation</td>
                              <td>{item?.acquaintances_korea_relation}</td>
                            </tr>
                          )}

                          {item?.accompanying_person && (
                            <tr>
                              <td>Do you have an accompanying person?</td>
                              <td>{item?.accompanying_person}</td>
                            </tr>
                          )}

                          {item?.visited_out_of_republic_korea && (
                            <tr>
                              <td>Have you ever visited another country other than the Republic of Korea?</td>
                              <td>{item?.visited_out_of_republic_korea}</td>
                            </tr>
                          )}

                          {item?.currently_employeed && (
                            <tr>
                              <td>Are you currently employeed? If yes, please enter the information below.</td>
                              <td>{item?.currently_employeed}</td>
                            </tr>
                          )}

                          {item?.currently_employeed == "Yes" && (
                            <tr>
                              <td>Enter the name of your employer or company.</td>
                              <td>{item?.workpNm}</td>
                            </tr>
                          )}

                          {item?.currently_employeed == "Yes" && (
                            <tr>
                              <td>Enter the phone number of your employer or company.</td>
                              <td>{item?.workpTel}</td>
                            </tr>
                          )}

                          {item?.monthly_income && (
                            <tr>
                              <td>Select the area that includes your monthly income (in USD).</td>
                              <td>{item?.monthly_income}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </StyledOrderBlock>
                </>
              )
            })
          }


          <StyledOrderBlock >
            <h2>Download History</h2>
            <div className="table-block table-responsive">
              <table width="100%">
                <thead>
                  <tr>
                    <th width="5%">#</th>
                    <th width="10%">Order ID</th>
                    <th width="22%">Date & Time</th>
                    <th width="23%">Downloaded File</th>
                    <th width="20%">IP Address</th>
                    <th width="20%">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.downloadHistory ? (
                    OrderDetails[0]?.downloadHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}{" "}
                            EST
                          </td>
                          <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <Link
                                to={
                                  OrderDetails?.uploadDoc !== undefined
                                    ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${item?.file1}`
                                    : null
                                }
                                href="#"
                                className="blue-btn"
                                target="_blank"
                                download
                                rel="noreferrer"
                              >
                                Download File{" "}
                                <span>
                                  <FaDownload className="download-icons" />
                                </span>
                              </Link>
                            )}
                          </td>
                          <td>{item?.ip}</td>
                          <td>{item?.timezone}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <form className="form-inline">
                <div className="form-group">
                  <label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control input-class"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    id="download_btn"
                    onClick={handleResendMail}
                    disabled={isLoading}
                  >
                    Sent Mail{" "}
                    <span>
                      <img src={rightArrow} alt="rightArrow" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </StyledOrderBlock>
          <AddCommentSection />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className="table-block table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th width="20%">Order ID</th>
                    <th width="20%">Date & Time</th>
                    <th width="15%">Team</th>
                    <th width="35%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails !== "undefined" && OrderDetails.length !== 0 &&
                    OrderDetails[0].remark ? (
                    OrderDetails[0].remark?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.us_date).format("MM/DD/YYYY")}
                            {"  "}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                              selectedData.dataId === item.id ? (
                              <div
                                rel="40"
                                className="btn blue-btn"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                              >
                                {" "}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel="40"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                                className="btn blue-btn"
                              >
                                {" "}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id="40">
                                  <textarea
                                    name="remark"
                                    className="form-control"
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : "N"}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : "N"}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : "N"}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : "N"}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : "N"}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : "N"}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>

        <StyledBottomBlock>
          <button
            type="button"
            className="blue-btn"
            data-toggle="modal"
            data-target="#refundModal"
            onClick={() => setShow(true)}
          >
            View Email Content{" "}
          </button>

           {OrderDetails?.length > 0 && OrderDetails[0]?.process_status == "Completed" && (
            <>
              <button
                type="button"
                className="blue-btn"
                data-toggle="modal"
                data-target="#defencePackModal"
                onClick={() => setShowDefence(true)}
              >
                Defence Pack 1 {" "}
              </button>
              <button
                type="button"
                className="blue-btn"
                data-toggle="modal"
                data-target="#defencePackModal1"
                onClick={() => setShowDefencePack(true)}
              >
                Defence Pack 2 {" "}
              </button>
              <button
                type="button"
                className="blue-btn"
                data-toggle="modal"
                data-target="#defencePackModal"
                onClick={() => setShowTransaction(true)}
              >
                Enter Transaction Details {" "}
              </button>
            </>
            )} 

          <MyModal
            show={show}
            close={setShow}
            size={"lg"}
            modalHeadTitle={"Email Content"}
            showFooter={true}
            primaryButtonName={"Close"}
            secondaryButtonName={"Send Whatsapp Message"}
            OrderDetails={OrderDetails?.length > 0 ? OrderDetails[0] : "" }
          >
            <EmailContentSection />
          </MyModal>
          {/* defence pack */}
           <MyModal
            show={showDefence}
            close={setShowDefence}
            modalHeadTitle={"Defence Pack Content"}
            showFooter={false}
            OrderDetails={OrderDetails?.length > 0 ? OrderDetails[0] : "" }
          >
            <DefencePackSection defencePackName={null} />
          </MyModal>
          <MyModal
            show={showDefencePack}
            close={setShowDefencePack}
            modalHeadTitle={"Defence Pack Content"}
            showFooter={false}
            OrderDetails={OrderDetails?.length > 0 ? OrderDetails[0] : "" }
          >
            <DefencePackSection defencePackName={"defencePack1"} />
          </MyModal>
          <MyModal
            show={showTransaction}
            close={setShowTransaction}
            size={"s"}
            modalHeadTitle={"Enter Defence Pack Details:"}
            showFooter={false}
            OrderDetails={OrderDetails?.length > 0 ? OrderDetails[0] : "" }
          >
            <TransactionModalSection setShowTransaction={setShowTransaction} />
          </MyModal> 
        </StyledBottomBlock>
      </div >
    </>
  );
};

export default ViewOrdersDetails;