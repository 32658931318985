import styled from "styled-components";

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  left: 0px;
  top: 0px;
  background: #fff;
  z-index: 999;
  padding: 10px 0px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 991px) {
    justify-content: flex-start;
    position: static;
  }

  .toggle-button-box {
    float: left;
    display: none;

    @media only screen and (max-width: 991px) {
      display: block;
      padding-left: 30px;
    }
    @media only screen and (max-width: 767px) {
      padding-left: 15px; 
    }

      .toggle-button {
        height: auto;
        width: 30px;
        background: transparent;
        border: none;
        box-shadow: none;
        padding: 0px;
      }
      .toggle-button span {
        display: block;
        width: 100%;
        height: 3px;
        margin-bottom: 5px;
        background: #0b3157;
      }
      .toggle-button span:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const StyledLogo = styled.div`
  padding-left: 30px;
  margin-bottom: 0;
  width: 280px;
  float: left;

  @media only screen and (max-width: 991px) {
    padding-left: 30px;
    margin-bottom: 0;
    width: auto;
    float: left;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 10px;
    width: 150px;
  }

  & img {
    max-width: 100%;
    vertical-align: top;

    @media only screen and (max-width: 350px) {
      max-width: 140%;
    }
  }
`;

export const StyledHeaderRight = styled.div`
  width: calc(100% - 280px);
  padding: 0px 30px;

  @media only screen and (max-width: 991px) {
    width: auto;
    padding: 0px 30px;
    order: 2;
    margin-left: auto;
  }
`;

export const StyledAdminHeaderRight = styled.div`
  float: right;

  .logout-icons {
    background: #18f;
    border-radius: 5px;
    display: inline-block;
    padding: 6px;
    width: 28px;
    text-align: center;
    color: #fff;
    margin-right: 10px;
    font-size: 28px;
  }

  & ul {
    margin: 0;
    margin-top: 5px;

    @media only screen and (max-width: 767px) {
      margin: 0px;
    }
  }
  & ul:after {
    content: "";
    clear: both;
    display: block;
  }
  & ul li {
    float: left;
    border-right: 1px solid #dadce0;
    padding: 0 30px;
  }
  & ul li:last-child {
    border: none;
    padding-right: 0px;
    list-style-type: none;
  }
  & ul li > a {
    display: inline-block;
    line-height: 45px;
    color: #0d8d45;
    font-size: 20px;
    position: relative;
    vertical-align: middle;
    @media only screen and (max-width: 767px) {
      padding: 10px 0px;
      font-size: 14px;
    }
  }
  & ul li a span {
    position: absolute;
    right: -10px;
    top: 10px;
    border-radius: 100%;
    width: 6px;
    height: 6px;
    background: #ed5e24;

    @media only screen and (max-width: 767px) {
      right: -5px;
    }
  }

  .admin-name {
    font-size: 16px;
    margin-right: 10px;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .user-top {
    display: inline-block;
    position: relative;
    margin-right: 10px;

    .online {
      width: 10px;
      height: 10px;
      background: #4ade3f;
      border-radius: 50%;
      position: absolute;
      display: inline-block;
      right: 0px;

      @media only screen and (max-width: 767px) {
        right: 5px;
      }
    }

    .user-img {
      width: 35px;
      height: 35px;
      display: inline-block;
      border-radius: 50%;
      overflow: hidden;
      vertical-align: middle;
    }
  }

  .dropdown-menu {
    right: 0px;
    left: auto;
    margin-top: 20px;
    border-radius: 0px;
    border: 0px;
    padding: 10px;
  }
  .dropdown {
    position: relative;
    display: contents;
  u
  .dropdown-menu a {
    padding: 7px 0px;
    font-size: 15px;
    text-transform: capitalize;
    color: #000;
    border-bottom: 1px solid #ddd;
    display: block;
  }
  .dropdown-menu a:hover {
    text-decoration: none;
  }
  .dropdown-menu .fa {
    background: #18f;
    border-radius: 5px;
    display: inline-block;
    padding: 5px;
    width: 28px;
    text-align: center;
    color: #fff;
    margin-right: 10px;
    font-size: 16px;
  }

  .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
  }

  .btn-primary {
    --bs-btn-color: green;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: none;
    --bs-btn-hover-color: green;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-active-color: green;
    --bs-btn-active-bg: none;
    --bs-btn-active-border-color: none;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: none;
    --bs-btn-disabled-bg: none;
    --bs-btn-disabled-border-color: none;
  }
`;
