import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/AuthService";

export const authenticate = createAsyncThunk("user/authenticate",async (user) => {
    try {
        const response = await AuthService.login(user);
        const data = response?.data;
        const token = data?.jwtToken;
        localStorage.setItem("user",JSON.stringify({accessToken: token,}));
        return data;
    } catch (error) {
        return error.response.data;
    }
  }
);

export const changePassword = createAsyncThunk("admin/changePassword",async (newPassword) => {
    try {
        const response = await AuthService.changePassword({
            newPassword: newPassword,
        });
        const data = response?.data;
        localStorage.removeItem("user");
        return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        loading: false,
        error: null,
        data: {},
        isAuth: localStorage.getItem("user") ? true : false,
        role: null,
        userInfoLoading: false,
    },
    reducers: {
        logout: () => ({
            loading: false,
            error: null,
            data: null,
            isAuth: false,
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(authenticate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(authenticate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.error.message;
        });
        builder.addCase(authenticate.fulfilled, (state, action) => {
            state.loading = false;
            if (typeof action.payload.data !== "undefined") {
                state.isAuth = true;
                state.data = action.payload.data;
            }
        });
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
